.DocChat-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.DocChat-upload-section
{
  width: 30%; /* Adjust the width as needed */
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
}
.DocChat-chat-container {
  width: 60%; /* Adjust the width as needed */
  padding: 20px;
  box-sizing: border-box;
}


.DocChat-upload-section {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.DocChat-chat-container {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.DocChat-heading {
  margin-bottom: 20px;
}

.DocChat-form-label {
  font-weight: bold;
}

.DocChat-form-input {
  width: 100%;
  margin-bottom: 10px;
}

.DocChat-button {
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white

}

#chatForm {
  text-align: center;
}



.DocChat-chat-container {
  /* Add any existing styles you have */

  /* Set the container to be a flex container with column direction */
  display: flex;
  flex-direction: column;
}

#chatResponseContainer {
  /* Add any existing styles you have */

  /* Make the container grow to fill available space */
  flex-grow: 1;
  height: 70vh;
  /* Set a maximum height for the chat container to make it scrollable */
  max-height: 90vh; /* Adjust the value based on your design */
  overflow-y: auto; /* Enable vertical scrolling */
}

#chatInput {
  /* Add any existing styles you have */

  /* Set the input field to stay at the bottom */
  align-self: flex-end;
  margin-top: auto; /* Push it to the bottom */
}

.bg-gray-800 {
  background-color: #333;
  color: #fff;
}

.text-white {
  color: #fff;
}

.p-2 {
  padding: 8px;
}

.border {
  border: 1px solid #ddd;
}

.rounded {
  border-radius: 4px;
}

.cursor-pointer {
  cursor: pointer;
}
