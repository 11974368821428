.stt-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjust as needed for your layout */
  }
  
  .stt-main-content {
    max-width: 50rem;
    width: 100%;
    min-height: 400px;
    height: auto;
    padding: 18px 18px 120px 18px;
    position: relative;
    resize: none;
    box-shadow: 0 12px 48px 0px rgb(109 117 141 / 20%);
    background: rgb(255 255 255);
    border: 0.5px solid rgb(231 233 245);
    border-radius: 16px;

  }
  
  .btn-style {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  #stt-button {
    margin: 0 5px;
    padding: 8px 12px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  #stt-button:hover {
    background-color: #45a049;
  }
  
  #stt {
    text-align: center;
  }
  