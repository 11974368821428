

.chat--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .chat-action-section {
    margin-top: 20px;
  }
  
  .chat-microphone-section {
    display: flex;
  }
  
   .btn {
    padding: 10px 20px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background: #00a876;
    margin-right: 20px;
  }
  

  
  .audio-container {
    margin-top: 20px;
  }
  
  .server-response {
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  

  #audio-heading {
    padding: 10px;
    margin-top: 20px;

  }

