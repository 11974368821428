/* SpellCheck.css */


  
  #sentence-input {
    text-align: center;
    
  }
  
  #loading {
    text-align: center;

  }
  #spell-check-text-area {
    width: 400px;
    height: 100px;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
  }
  
  #spell-check {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  #spell-check:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  #sentence-output {
    text-align: center;
    margin-top: 20px;
  }
  
  #corrected-sentence, #loading {
    margin-top: 10px;
  }
  
 

.sendButton{
    background-color: white;
}