/* styles.css */

/* Form container */
.form-container {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  
  /* Input field */
  .input-field {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #211010;
    border-radius: 4px;
    width: 60%;
    margin-top: 20px;
    margin-left: 10px

  }
  
  /* Submit button */
  .submit-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  