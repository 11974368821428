/* ChatWIthURL.css */

.body {
    width: 100%; /* Set the width of the body to 100% */
  }
  
  /* Add this style to increase the width of the text area to 60% */
  #url {
    width: 30%;
  }
  
  /* Add this style to increase the width of the text area to 60% */
  #chatInput {
    width: 50%;
    padding: 20px;
  }

  #chatForm {
    height: 600px;

  }
  